.customDatePicker {
    /* border: 1px solid red !important; */
    border: none;
    outline: none;
    border-radius: 5px;
    width: 120px;
    height: 30px;
    font-size: 0.75rem;
    padding-left: 8px;
    cursor: pointer;
    color: var(--text_color_one);
}

.err-msg-absolute {
    position: absolute;
}

.input-err {
    border: 1px solid var(--redTxt) !important;
    /* color: var(--redTxt) !important; */
}

.err-msg {
    color: var(--redTxt);
    margin-top: .3rem;
    font-size: .7rem;
}

.customDatePicker-tab {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 8px;
}

.calenderClass {
    text-transform: capitalize !important;
}

.calenderClass::placeholder {
    color: var(--text_color_two);
}

.react-datepicker {
    width: 20rem !important;
    display: flex !important;
    justify-content: center !important;
    border: 1px solid var(--primary) !important;
}

.react-datepicker__day-names,
.react-datepicker__week {
    display: flex;
    /* border: 1px solid red; */
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    background-color: var(--background-color) !important;
    color: var(--primary) !important;
    border: 1px solid var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;
}

.react-datepicker__day-names {
    background-color: #fff;
    border: 1px solid var(--primary) !important;
    border-bottom: 0px !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
    border-bottom-color: var(--background-colorSix) !important;
    opacity: 0 !important;
}

.react-datepicker-popper {
    z-index: 999999 !important;
}

.custom-select-style {
    background-color: transparent;
    border: none;
    outline: none;
    color: var(--text_color_five);
}

.custom-select-style:focus {
    border: none;
    outline: none;
}

.react-datepicker__header {
    padding: 0px !important;
}

.calender-container {
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    padding: 15px 10px;
    gap: 4.5rem;
    border-radius: 5px 5px 0px 0px;
    border: 1px solid var(--primary);
    border-top: 0px !important;
    border-bottom: 0px !important;
    width: 20rem !important;
    background-color: var(--background-colorSix);
}

.calender-btns-container {
    display: flex;
    gap: 0.3rem;
}

.btn-calender {
    /* border: 1px solid red; */
    height: 20px;
    border: none;
    outline: none;
    width: 20px;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
}

.pForm_datePicker {
    border: 1px solid var(--background-colorThree);
    height: 52px;
    cursor: pointer;
    width: 100%;
    border-radius: 5px;
    padding: 0rem 1rem;
    color: var(--text_color_three);
    /* display: flex; */
}

.customDatePicker:focus {
    border: none;
    outline: none;
}

.pForm_datePicker:focus {
    outline: none;
}

.customDatepickerContainer {
    /* border: 1px solid blue; */
    width: 100%;
    display: flex;
    position: relative !important;
}

.customDatepickerContainer-parent {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.datepicker-label {
    position: absolute;
    transform: translate(0, -9px) scale(0.8);
    background: #fff;
    color: var(--text_color_two);
    padding: 0px 5px;
}

.calenderSvg {
    position: absolute;
    right: 7%;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__input-container {
    width: 100% !important;
    /* border: 1px solid red; */
}

.react-datepicker-wrapper {
    position: relative !important;
}

/* FLOATING LABEL */

#float-label {
    display: flex;
    flex-direction: column;
    position: relative;
    /* min-width: 350px; */
}

#float-label input {
    width: 100%;
    height: 52px;
    padding: 0px 16px 0 13px;
    outline: 0;
    border: 1px solid var(--border_color_two);
    border-radius: 4px;
    background: #fff;
    color: var(--text_color_three);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.8rem;
    transition: all 0.1s ease;
}


#float-label input:focus {
    border: 1px solid var(--primary);
}

#float-label:focus-within label {
    transform: translate(0, -5px) scale(0.75);
}

#float-label label {
    font-size: 0.85rem;
    padding: 0 5px;
    color: var(--text_color_two);
    pointer-events: none;
    left: 10px;
    position: absolute;
    transform-origin: top left;
    transition: all 0.2s ease-out;
    background: #fff;
    transform: translate(0, 17px) scale(0.9);
}

#float-label .Active {
    transform: translate(0, -5px) scale(0.75);
    background: #fff;
    /* border: 1px solid red; */
}

.fmcInput {
    width: 100%;
    height: 52px;
    padding: 0px 16px 0 13px;
    outline: 0;
    border: 1px solid var(--border_color_two);
    border-radius: 4px;
    background: #fff;
    color: var(--text_color_three);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.8rem;
}

.range-container {
    /* border: 1px solid red; */
    width: 10rem;
    /* display: none; */
    position: absolute;
    top: 48px;
    /* bottom: 0; */
}

.rangeSlider {
    width: 100%;
    height: 7px;
}

.range-container-values {
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;
}

.range-container-values>p {
    color: var(--text_color_one);
    font-size: 0.7rem;
    font-weight: 500;
}

.uppercaseText {
    text-transform: uppercase !important;
}

@media only screen and (max-width: 610px) {
    #float-label label {
        transform: translate(0, 15px) scale(0.9);
        font-size: 1rem;
        transform: translate(0, 15px) scale(0.9);
    }

    .pForm_datePicker {
        font-size: 1rem;
    }

    #float-label .Active {
        transform: translate(0, -9px) scale(0.8);
    }

    #float-label:focus-within label {
        transform: translate(0, -9px) scale(0.8);
    }

    #float-label input {
        font-size: 1rem;
    }
}