.stepperContainer {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    margin-bottom: 2rem;
}

.stepperDiv {
    background-color: var(--background-color);
    cursor: pointer;
    padding: 0.5rem;
    display: flex;
    gap: 1rem;
    min-width: 11rem;
    box-shadow: var(--boxShadowTwo);
    border-radius: 10px;
}

.stepperDiv_active {
    background-color: #fff;
}

.signal_pform {
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: var(--border_color_three);
    color: var(--text_color_three);
}

.small_text_stepper {
    font-size: 0.75rem;
}

.title_stepper>p:last-child {
    font-size: 0.8rem;
    color: var(--text_color_three);
}