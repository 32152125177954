:root {
    --primary: #5086FF;
    --gray: #60646A;
    --grayTwo: #76767B;
    --black: #1A1B1D;
    --bgBlue: #F1F5FF;
    --bgBlueTwo: #F6F9FF;
    --bgBlueThree: #D7E4FF;
    --blue: #000096;
    --borderColor: #B1C9FF;
    --borderColorTwo: #ECF2FF;
    --gradient: #DFE9FF;
    --gradientColor: linear-gradient(95.84deg, #002A96 5.92%, #5086FF 95.45%);
}

.pdf-container {
    width: 600px;
    height: 842px;
    margin: auto;
    padding: 1rem;
    margin-bottom: 1rem;
    /* letter-spacing: 0.01px; */
}

.headTitle {
    color: #000;
    display: flex;
    align-items: center;
    font-weight: 700;
    vertical-align: middle;
}

.headTitle>span {
    color: var(--primary);
}

.pdfContent {
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    /* border: 1px solid red; */
}

.pdfContent-header {
    /* border: 1px solid blue; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* gap: 1.7rem; */
}

.headTitle-gray {
    font-size: .69rem;
    font-weight: 500;
    color: var(--gray);
}

.policyDetailsContainer {
    display: flex;
    gap: 10px;
    /* height: 13rem; */
}

.policyDetailsContainer>* {
    width: 50%;
}

.policyHolderDetails {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    gap: 5px;
}

.policyHolderDetailsBlock {
    /* border: 1px solid blue; */
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: var(--bgBlue);
    border-radius: 6px;
    height: 100%;
    justify-content: space-between;

}

.flex-container-pdf {
    /* border: 1px solid red; */
    /* height: 15rem; */
    display: flex;
    gap: 10px;
}

.flex-container-pdf-left {
    width: 55%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.flex-container-pdf-right {
    width: 45%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.policyHolderDetailsBlock_blue {
    background-color: var(--bgBlueThree);
    width: 44%;
}

.policyHolderDetail-container {
    display: flex;
    justify-content: space-between;
}

/* .policyHolderDetail-container>*{
width: 50%;
} */

.policyHolderDetailsBlock {
    padding: 10px;
}

.titleDescription-policy {
    word-break: break-word;
    max-width: 200px;
}

.titleDescription-policy>p:first-child {
    color: #000;
    font-size: .7rem;
}

.titleDescription-policy_copy>p:first-child {
    color: var(--black);
    font-weight: 600;
    font-size: .7rem;
}

.titleDescription-policy_copy>p:last-child {
    color: var(--gray) !important;
    font-weight: 600;
    font-size: .7rem;
}

.service-container {
    padding: 10px;
    background-color: var(--bgBlue);
    border-radius: 10px;
    /* border: 1px solid var(--borderColor); */
    /* height: 270px; */
}

.titleDescription-policy>p:last-child {
    color: var(--black);
    /* font-weight: 700; */
    font-size: .6rem;
    letter-spacing: -0.2px;
}

.policyDetails {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.calendar {
    border: 1px solid var(--borderColor);
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 6px;
}


.calendar_container_pdf {
    /* border: 1px solid red; */
    display: flex;
    gap: 5px;
}

.calendar-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 6px;
}

.calendar-year {
    /* border: 1px solid yellow; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 10px;
    font-size: 1rem;
    border-bottom: 1px solid var(--borderColor);
    background: #F1F5FF;
    border-radius: 6px 6px 0px 0px;
}

.calendar-year>p {
    color: var(--blue);
    font-weight: 700;
}

.calendar-details>p:first-child {
    color: var(--black);
    font-weight: 600;
    font-size: .6rem;
}

.calendar-details>p:nth-child(2) {
    color: var(--primary);
    font-weight: 700;
    font-size: 2.4rem;
}

.calendar-details>p:last-child {
    color: var(--black);
    font-weight: 600;
    font-size: 1.1rem;
}

.vehicleDetailsContainer {
    /* border: 1px solid red; */
    display: flex;
    gap: 10px;
}

.alliance_head_details {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
}

.alliance_head_details>p {
    color: var(--blue);
    font-size: .61rem;
    font-weight: 600;
}

.alliance_head_details>img {
    width: 4rem;
}




.vehicle-details-block {
    /* width: 50%; */
    border-radius: 5px;
    border: 1px solid var(--borderColor);
    padding-bottom: .5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    /* align-items: ; */
}

.comp-details {
    display: flex;
    flex-direction: column;
}

.comp-details-container {
    /* width: 50%; */
    gap: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.comp-details-block {
    border: 1px solid var(--borderColorTwo);
    background-color: var(--bgBlueTwo);
    padding: 10px 10px;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    /* justify-content: flex-start; */
    /* align-items: center; */
    /* gap: 1.5rem; */
    margin-bottom: 1.7rem;
    border-radius: 5px;
}
.Alliance_Assure_fixed{
    gap: .9rem;
}

.support_container_pdf {
    /* border: 1px solid red; */
    display: flex;
    gap: 10px;
}

.support_container_pdf>p {
    /* color: red; */
    /* font-weight: 600; */
    color: var(--black);
    font-size: .7rem;

}

.policy-container-pdf {
    border: 1px solid var(--borderColor);
    position: relative;
    display: flex;
    flex-direction: column;
    /* gap: 5px; */
    padding: 5px;
    border-radius: 5px;
}

.policy-container-pdf>p {
    position: absolute;
    top: -1.5rem;
    /* border: 1px solid red; */
    height: 1.5rem;
    padding: 0px 13px;
    border-radius: 5px 5px 0px 0px;
    left: 1rem;
    font-size: .7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: var(--blue);
    color: #fff;
}

.payment-head-value {
    /* border: 1px solid blue; */
    display: flex;
    justify-content: space-between;
    padding: 3px;
}

.payment-head-value>p {
    font-size: .7rem;
}

.payment-head-value>p:first-child {
    color: var(--primary);
    font-weight: 500;
}

.payment-head-value>p:last-child {
    color: var(--black);
    font-weight: 600;
}

.support_container_pdf>img {
    width: 1rem;
}

.comp-details-block>p {
    font-size: .65rem;
    color: var(--gray);
    font-weight: 600;
}

.vehicle-details-header {
    border-radius: 5px 5px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 13px;
    gap: 8px;
    font-weight: 700;
    color: var(--black);
    background-color: var(--bgBlue);
}

.vehicle-details-header>img {
    width: 2rem;
}

.title-desc-vehicle {
    display: flex;
    justify-content: space-between;
    padding: 10px 13px;
    word-break: break-word;
}

.title-desc-vehicle>p:first-child {
    color: var(--grayTwo);
    font-weight: 600;
    /* border: 1px solid blue; */
    min-width: fit-content;
    padding-right: 4px;
    font-size: .7rem;
}

.title-desc-vehicle>p:last-child {
    color: var(--black);
    font-weight: 600;
    font-size: .7rem;
    /* border: 1px solid green; */
}

.headTitle-compDetails>p:first-child {
    color: var(--gray);
    font-size: .85rem;
    font-weight: 700;
}

.headTitle-compDetails>p:last-child {
    color: var(--black);
    font-size: .85rem;
    font-weight: 600;
}

.planDetails-container {
    border: 1px solid var(--borderColor);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: .6rem;
    border-radius: 10px;
    margin-top: 1rem;
}

.planDetails-container>p {
    color: var(--blue);
    font-size: .9rem;
    font-weight: 700;
}

.planDetails-summary>p:last-child {
    color: var(--gray);
    font-size: .65rem;
}

.planDetails-webLink>p:first-child {
    color: var(--black);
    font-weight: 400;
    font-size: .65rem;
}

.planDetails-webLink>p:last-child {
    color: var(--primary);
    font-weight: 700;
    font-size: .7rem;
}

.footer>p:first-child {
    color: var(--black);
    font-size: .7rem;
    text-align: center;
}

.footer>p:last-child {
    color: var(--black);
    font-weight: 600;
    font-size: .7rem;
    text-align: center;
}

.covered-container {
    border: 1px solid var(--borderColor);
    margin-top: 1rem;
    border-radius: 10px;
    /* height: 10rem; */
}

.covered-container-header {
    background: #F1F5FF;
    padding: 10px;
    color: var(--blue);
    border-radius: 10px 10px 0px 0px;
    font-weight: 500;
    border-bottom: 1px solid var(--borderColor);
}

.covered-content {
    margin: 10px 10px;
    /* border: 1px solid red; */
}

.covered-content>p:first-child {
    color: var(--blue);
    font-weight: 700;
    font-size: .8rem;
    margin-bottom: 5px;
}

.covered-content>p:last-child {
    color: var(--gray);
    font-weight: 500;
    font-size: .6rem;
}

.paragraphPDF {
    /* border: 1px solid red; */
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    align-items: flex-start;
}

.paragraphPDF>p {
    color: var(--gray);
    font-weight: 500;
    font-size: .6rem;
}

.covered-content-p>p {
    color: var(--gray);
    font-weight: 500;
    font-size: .6rem;
}

.covered-content-p>span {
    color: var(--primary);
    font-weight: 500;
    font-size: .6rem;
}



.covered-content-pp {
    color: var(--gray) !important;
    font-weight: 500 !important;
    font-size: .6rem !important;
}

.pdf-two-footer {
    /* border: 1px solid red; */
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    gap: 1.5rem;
}

.pdf-two-footer>* {
    /* border: 1px solid blue; */
    flex: 1 1;
}

.pdf-two-footer-block-comp>p:first-child {
    color: var(--primary);
    font-weight: 700;
}

.pdf-two-footer-block-comp>p:last-child {
    color: var(--black);
    font-weight: 400;
    font-size: .7rem;
}

.footer-head-desc {
    color: var(--black);
    font-weight: 700;
    font-size: .7rem;
    display: flex;
}

.footer-head-desc>p {
    font-weight: 400;
}

.ptag_inOneLine {
    display: flex;
    gap: 5px;
}

.detail-block-padding {
    padding-top: 2.5rem;
}

body {
    /* background-color: silver; */
}

.header-insurer {
    position: relative;
    /* border: 1px solid red; */
    height: 3rem;
    margin-bottom: 1rem;
}

.header-insurer>img {
    position: absolute;
}

.header-insurer>p {
    text-align: center;
    padding-left: 3.5rem;
    padding-top: .6rem;
    font-size: .9rem;
    z-index: 1;
    position: relative;
    font-weight: 600;
    text-decoration: underline;
}

.block-head-value-pol {
    /* border: 1px solid red; */
    display: flex;
    gap: 5px;
    align-items: center;
}

.policy-pddf-container {
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
}

.block-head-value-pol>p {
    font-size: .65rem;
}

.block-head-value-pol>p:first-child {
    min-width: fit-content;
    font-weight: 500;
}

.block-head-value-pol>p:last-child {
    color: var(--gray);
    font-size: .55rem;
}

.broker-details-pdf {
    font-size: .5rem;
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
}

.content-block-pdf {
    /* border: 1px solid red; */
    font-size: .5rem;
    margin-top: 3px;
}

.content-block-pdf>p:first-child {
    color: var(--blue);
    font-weight: 600;
}

.content-block-pdf-two>p:first-child {
    color: var(--black);
    font-weight: 600;
}

.content-block-pdf>p:last-child {
    color: var(--gray);
    font-weight: 500;
}

.broker-head-value {
    display: flex;
    gap: 5px;
}

.broker-head-value>p:first-child {
    color: #000;
    font-weight: 600;
}

.broker-head-value>p:last-child {
    color: var(--gray);
    /* font-weight: 600; */
}

.content-block-pdf-three>p:first-child {
    color: black !important;
    font-weight: 500;
}

.content-block-pdf-three>p>span {
    color: var(--gray);
    font-weight: 500;
}

.distinguishTable-container {
    border: 1px solid var(--borderColor);
    margin-top: 5px;
    font-size: .5rem;
    border-radius: 4px;
}

.distinguishTable-content {
    display: flex;
}

.distinguishTable-content>* {
    flex: 1 1;
}

.distinguishTable-content-left {
    border-right: 1px solid var(--borderColor);
}

.distinguishTable-header {
    display: flex;
    padding: 7px;
    gap: 10px;
    padding-bottom: 5px;
    padding-top: 5px;
    border-radius: 4px;
    background-color: var(--bgBlue);
    border-bottom: 1px solid var(--borderColor);
}

.distinguishTable-content-block {
    padding: 7px;
    color: var(--gray);
}

.distinguishTable-header>* {
    flex: 1 1;
}

.download-link-footer>p:first-child {
    color: #000;
    font-style: italic;
    font-weight: 500;
}

.download-link-footer>a {
    color: var(--primary);
    font-size: .4rem;
    letter-spacing: -.3px;
}

.block-head-value-pol-bold>p:last-child {
    color: var(--black);
    font-size: .6rem;
    font-weight: 700;
}

.alliance_section {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    right: 0;
    width: 50%;
}

.header-container-pdf {
    /* border: 1px solid blue; */
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    /* align-items: center; */
}

.alliance_section>p {
    text-align: right;
    font-size: .45rem;
}

.alliance_heading {
    color: var(--blue);
    font-size: .67rem !important;
    font-weight: 700;
}

.contact_alliance {
    color: var(--gray);
    /* font-size: 1rem; */
}

.contact_alliance_blue {
    color: var(--primary);
}

.pdf-table {
    margin-top: .5rem;
}

.table-row {
    display: flex;
}

.table-cell {
    flex: 1;
    border: 1px solid var(--borderColor);
    padding: 5px;
    text-align: center;
    font-size: 0.5rem;
    max-width: 70.38px;
    word-break: break-word;
}

.header {
    font-weight: 500;
    font-size: 0.5rem;
    background-color: var(--bgBlue);
    color: var(--gray);
}

.table-cell-one {
    max-width: 70.38px;
    word-break: break-word;
}

.table-cell-two {
    max-width: 70.38px;
    word-break: break-word;
}

.table-cell-three {
    max-width: 70.38px;
    word-break: break-word;
}

.table-cell-four {
    max-width: 70.38px;
    word-break: break-word;
}
/* ==============Terms and Condition ================== */
.terms_condition_title{
color: #000096;
font-size: 12px;
margin-top: 1rem;

font-weight: 600;
}
.term_content{
color: #606A6A;
font-size: 10px;
font-style: normal;
font-weight: 500;
line-height: 17px; /* 170% */
letter-spacing: 0.1px;
}
.TurnaroundTime{
color:black;
font-size: 10px;
font-style: normal;
font-weight: 600;
line-height: 17px; /* 170% */
letter-spacing: 0.1px;
}
.stong_text{
    color: black;
}
.terms_for_Cab{
    color: #000;
    font-size: 12px;
    padding: 0.4rem 0.2rem;
    font-weight: 600;
    margin-top: 0.7rem;
    border-radius: 5px 5px 0px 0px;
    line-height: 17px; /* 170% */
    border: 1px solid #B1C9FF;
    background-color: var(--bgBlueThree);
    margin-bottom: 0.5rem;

}
.rate_for_cunstmer{
color: #1A1B1D;
font-size: 10px;
font-style: normal;
font-weight: 500;
line-height: 17px; /* 170% */
letter-spacing: 0.1px;
}
.terms_forPanIndida{
    color: #606A6A;
    font-size: 10px;
    margin-top: 1rem;
    font-weight: 650;
    line-height: normal;
    letter-spacing: 0.1px;
}
.note-text_terms{
    color: black;
    font-size: 7px;
    margin-top: 1rem;
    font-weight:400;
    line-height: normal;
    letter-spacing: 0.1px;
}