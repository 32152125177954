.Calci_Container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.vechileType {
    display: flex;

}

.vechileType>div {
    border-radius: 10px;
    border: 1px solid #5086FF;
    margin: 10px;
    background: #FFF;
    padding: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #5086FF;
    font-family: Poppins;
    font-size: 15px;
}

.basic_details_conatiner {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;

}

.basic_details_conatiner>div {
    display: flex;
}

.addons_box {}

.addons_felx {
    display: flex;
    width: 50%;
    align-items: center;
    margin-top: 10px;
    justify-content: space-between;
}

.ncb-btn-editform {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}
.nab_discount_conatainer>div{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}


.ncbBtn-editForm {
    outline: none;
    display: flex;
    padding: 8px;
    align-items: center;
    border: 1px solid var(--border_color_two);
    border-radius: 5px;
    background-color: #fff;
    /* font-size: .75rem; */
    color: var(--text_color_one);
    cursor: pointer;
}

.activeNcbBtn {
    color: var(--primary);
    background-color: var(--border_color_three);
}

.special_Discount {
    display: flex;
    align-items: center;
}

.inputFilded_discount {
    height: 35px;
    width: 5rem;
    text-align: center;
    border-radius: 5px;
    border: 1px solid #B1C9FF;
    background: #FFF;
    margin-left: 1rem;
    
}

.inputFilded_discount:focus {
    border: 2px solid red;
}
.formBtnBox{
    display: flex;
    justify-content: space-between;
}
.btn-Preview{
    color: #5086FF;
font-family: Poppins;
font-size: 15px;
font-style: normal;
font-weight: 500;
border-radius: 5px;
border: 1px solid #B1C9FF;
line-height: 30px; /* 200% */
}
.btn-calci {
    width: 142px;
    height: 52px;
    padding: 10px 25px;
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    margin-bottom: 1rem;
    align-items: center;
}

.switch-container-modal {
    display: flex;
    /* width: 50%;
    margin: auto; */
    justify-content: space-between;
    align-items: center;
  }
  
  .switchBtn-container>* {
  
    height: 2.8rem;
    width: 5rem;
  
    background-color: #fff;
    cursor: pointer;
  }
  
  #activeStwitchbtn {
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    border-top: 1px solid #B1C9FF;
    border-bottom: 1px solid #B1C9FF;
    border-left: 1px solid #B1C9FF;
    border-right: none;
    transition: width 2s;
  }
  
  #noactiveStwitchbtn {
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    border-top: 1px solid #B1C9FF;
    border-bottom: 1px solid #B1C9FF;
    border-right: 1px solid #B1C9FF;
    border-left: none;
    transition: width 2s;
  }
  
  .active-switchclass {
    background-color: var(--Primary-Blue, #5086FF);
    color: white;
    font-size: 12px;
  }
  
  .Non-active-switch-class {
    color: #5086FF;
    font-size: 12px;
  }
  
  .textnilldeppervious {
    color: #000;
    font-size: 12px;
  }
  #Seclected_VechileType{
    border: 1px solid green;
  }
  .disable_ncb{
    background-color: rgba(240, 248, 255, 0.058);
    cursor:not-allowed;
  }
  .premium_value{
    
  }