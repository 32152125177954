.calci_container{
    width: 70%;
margin: auto;
margin-left: 7rem;
background: white;
box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
display: flex;
/* height: 75vh; */
padding: 3rem 1rem  ;
margin-top: 3rem;
border-radius: 10px;
justify-content: center;
   
}
.main_container{
    display: flex;
    height: 300px;
    background: linear-gradient(100deg, #DFE9FF -0.55%, #FEE6E7 106.25%);
    align-items: center;

    
    justify-content: center;
}