/* PremiumBreakup.css */

.container {
    padding: 20px;
    /* background: linear-gradient(to bottom, #dbeafe, #ede9fe); */
    /* min-height: 100vh; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .content {
    /* max-width: 800px; */
    width: 100%;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    border-radius: 10px;
    margin-bottom: 16px;
  }
  
  .highlight {
    color: #4f46e5;
    font-weight: bold;
  }
  
  .buttons {
    display: flex;
    gap: 10px;
  }
  
  .outline-button, .primary-button {
    padding: 8px 12px;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
  }
  
  .outline-button {
    border: 1px solid #4f46e5;
    background: white;
    color: #4f46e5;
  }
  
  .primary-button {
    background: #4f46e5;
    color: white;
    border: none;
  }
  
  .card {
    background: white;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  
  .card-content {
    color: #1A1B1D;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    padding: 10px;
  }
  
  .section-title {
    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 214.286% */
  }
  
  .details-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    margin-bottom: 16px;
  }
  .details-grid2{
    display:  flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .detail-item {
    display: flex;
    justify-content: space-between;
  }

  
  .total {
    font-weight: bold;
    padding: 10px 10px;
    border-radius: 10px;
    background: var(--bgBlue);
    margin-top: 5px;
  }
  
  .total-section {
    border-top: 1px solid #ddd;
    padding-top: 10px;
    margin-top: 15px;
  }
  
  .final-total {
    font-size: 18px;
    font-weight: bold;
    color: #4f46e5;
  }
 .BackArrow_Cls{
  display: flex;
  margin-right: 5px;
  cursor: pointer;
 }
