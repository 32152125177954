.passBook_container {
    /* background: #f0f2f5; */
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.filter_buttons {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-bottom: 2rem;
}

.filter_btn {
    background-color:#5086ff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.filter_btn.active,
.filter_btn:hover {
    background-color: #388E3C;
}

.transaction_list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.transaction-card {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 15px 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.transaction-header {

    font-weight: bold;
}


.transaction-details {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.transaction-type {
    padding: 5px 10px;
    border-radius: 12px;
    color: #fff;
}

.transaction-type.debit {
    background-color: #FF5722;
}

.transaction-type.credit {
    background-color: #4CAF50;
}


.running-total {
    color: black;
    font-weight: bold;
}

.debitttt {
    color: #FF5722;
    font-weight: bold;
}

.creditttt {
    color: #4CAF50;
    font-weight: bold;
}

.skeleton {
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.skeleton-box {
    background-color: #e0e0e0;
    border-radius: 5px;
    margin: 4px 0;
}

.transaction-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.pagination-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.dropdown-select {
    padding: 6px 12px;
    border: 2px solid #4CAF50;
    border-radius: 6px;
    background-color: #fff;
    color: #333;
    cursor: pointer;
}

.dropdown-select:focus {
    outline: none;
    border-color: #45a049;
}